@media screen and (min-width: 1900px) {
  .fullscreen-container {
    max-width: 1950px !important;
  }
}

@media screen and (min-width: 2400px) {
  .fullscreen-container {
    max-width: 2450px !important;
  }
}

@media screen and (min-width: 2900px) {
  .fullscreen-container {
    max-width: 2950px !important;
  }
}
