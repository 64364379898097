.second-column {
  width: 25%;
}
.MuiPickersBasePicker-pickerViewLandscape {
  margin: 0 auto;
}

.MuiPickersDay-daySelected {
  color: #fff !important;
}

.first-column {
  width: 72%;
}

.big-daily {
  margin-top: 1rem;
  min-width: 300px;
}

.small-daily {
  display: none;
}

.customers {
  width: 40%;
}

.sales {
  width: 55%;
}

.date {
  display: none;
}

@media screen and (max-width: 1600px) {
  .dashboard-wrapper {
    flex-direction: column;
  }

  .second-column {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .first-column {
    width: 100%;
  }

  .big-daily {
    display: none;
  }

  .small-daily {
    display: block;
    width: 40%;
  }

  .customers {
    width: 50%;
  }

  .sales {
    width: 100%;
    margin-top: 2.5rem;
  }

  .bottom-wrapper {
    flex-wrap: wrap;
  }

  .calendar {
    display: none;
  }

  .date {
    display: block;
    text-align: right;
    margin-bottom: 7px;
    color: #b5b5c3;
  }
}
