.chart-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: grey;
  opacity: 0.4;
  width: 100%;
  height: 100%;
}

.chart-label {
  border: 1px solid #49b8c4;
  border-radius: 6px;
  padding: 6px 8px;
  color: white;
  background-color: #49c499;
  z-index: 3;
  position: absolute;
  top: 45%;
  left: 45%;
  font-size: 20px;
}
