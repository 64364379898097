.react-pdf__Page__annotations,
.annotationsLayer,
.react-pdf__Page__textContent,
.textLayer {
  display: none;
}

.approval-content-wrapper {
  display: flex;
}

.approval-content-preview {
  order: 1;
}

.approval-content-data {
  order: 2;
}

@media screen and (max-width: 2100px) {
  .approval-content-wrapper {
    flex-direction: column;
  }
  .approval-content-preview {
    order: 2;
  }

  .approval-content-data {
    order: 1;
  }
}
