.tile-wrapper {
  background-color: white;
  color: rgba(0, 0, 0, 0.24);
  transition: background-color 0.5s;
  min-width: 220px;
}

.tile-wrapper:hover {
  background-color: #64a70b;
  color: white;
}

.tile-wrapper:hover .svg-icon {
  fill: white;
  fill-opacity: 1;
}

.svg-icon {
  fill: rgba(0, 0, 0, 0.24);
  fill-opacity: 1;
}

@media screen and (max-width: 1600px) {
  .tile-wrapper {
    min-width: 190px;
  }
}

@media screen and (max-width: 1100px) {
  .tile-wrapper {
    min-width: 130px;
  }
}
