.rmdp-week {
  display: flex;
  justify-content: space-between;
  min-width: 340px;
}

@media screen and (max-width: 1780px) {
  .rmdp-week {
    display: flex;
    justify-content: space-between;
    min-width: 330px;
  }
}

@media screen and (max-width: 1740px) {
  .rmdp-week {
    display: flex;
    justify-content: space-between;
    min-width: 320px;
  }
}

@media screen and (max-width: 1700px) {
  .rmdp-week {
    display: flex;
    justify-content: space-between;
    min-width: 310px;
  }
}
@media screen and (max-width: 1650px) {
  .rmdp-week {
    display: flex;
    justify-content: space-between;
    min-width: 300px;
  }
}
